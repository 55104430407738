import React from 'react'
import renderBlok from '@renderBlok'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { Box } from '@material-ui/core'
import { H3, PageContainer, ContentContainer } from '@system'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => ({
  body: {
    backgroundColor: theme.palette.primary.main,
    '& h1': {
      color: `${theme.palette.common.white} !important`,
    },
    '& h3': {
      color: `${theme.palette.common.white} !important`,
    },
    '& p': {
      color: `${theme.palette.common.white} !important`,
    },
    '& h5': {
      color: `${theme.palette.common.white} !important`,
    },
  },
  headline: {
    color: theme.palette.secondary.main,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: '1rem 0 2rem',
    margin: 0,
    [theme.breakpoints.up('sm')]: {
      paddingBottom: '3rem',
    },
  },
  item: {
    width: '25%',
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
  },
  itemContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  flexBox: {
    width: 'calc(100% + 48px)',
    margin: '-24px',
    display: 'flex',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '0px',
    },
  },
  cards: (props) => ({
    padding: '24px',
    display: 'flex',
    flexDirection: props.productCards.length === 1 ? 'row' : 'column',
    flexGrow: 0,
    maxWidth:
      props.productCards.length > 2
        ? '25%'
        : props.productCards.length === 1
        ? '55%'
        : '30%',
    flexBasis:
      props.productCards.length > 2
        ? '25%'
        : props.productCards.length === 1
        ? '55%'
        : '30%',
    [theme.breakpoints.between('sm', 'xl')]: {
      alignItems: props.productCards.length === 1 && 'center',
    },
    [theme.breakpoints.down('md')]: {
      flexGrow: 0,
      maxWidth: props.productCards.length === 1 && '70%',
      flexBasis: props.productCards.length === 1 && '70%',
    },
    [theme.breakpoints.down('sm')]: {
      flexGrow: 0,
      maxWidth: props.productCards.length === 1 ? '100%' : '50%',
      flexBasis: props.productCards.length === 1 ? '100%' : '50%',
    },
    [theme.breakpoints.down('xs')]: {
      flexGrow: 0,
      maxWidth: '100%',
      flexBasis: '100%',
      flexDirection: props.productCards.length === 1 && 'column',
      padding: '20px',
    },
    '& #product-logo': {
      paddingRight: props.productCards.length === 1 && '40px',
    },
  }),
}))

const ProductsIntroModule = (props) => {
  const { headline, productCards, variants, isFullWidth = true } = props.blok
  const classes = useStyles(props.blok)
  return (
    <SbEditable content={props.blok}>
      <PageContainer parentWidth={isFullWidth}>
        <Box
          className={classNames({
            [classes.body]: variants === 'blue-bg',
          })}
        >
          <ContentContainer>
            <Box pb={6}>
              <H3 className={classes.headline}>{headline}</H3>
              <Box className={classes.flexBox}>
                {productCards &&
                  productCards.map((blok) => (
                    <Box key={blok._uid} className={classes.cards}>
                      {renderBlok(blok, {
                        foregroundColor:
                          variants === 'blue-bg' ? 'light' : 'dark',
                      })}
                    </Box>
                  ))}
              </Box>
            </Box>
          </ContentContainer>
        </Box>
      </PageContainer>
    </SbEditable>
  )
}

export default ProductsIntroModule
